import React from "react";
import {ProjectsStyles} from "./styles";
import stocks from '../../../../assets/images/stock-photo.svg'
import {Button} from "../button/styles";

function Projects() {
    return (
        <>
            <ProjectsStyles id='projects'>
                <div>
                    <h2>Projects</h2>
                    <div className='projects'>
                        <img src={stocks} alt="Stock Photo"/>
                        <div>
                            <p>
                                We have worked on projects in various fields of technology,
                                and have the experience required to help your business succeed.
                            </p>
                            <Button>View Projects</Button>
                        </div>
                    </div>
                </div>
            </ProjectsStyles>
        </>
    )
}

export default Projects
