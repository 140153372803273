import styled from 'styled-components';

export const ProjectsStyles = styled.div`
  .projects {
    display: flex;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  } 
  
  .projects > div {
    margin-left: 4%;
    line-height: 2;
  }
  
  .projects > div > p {
    margin-top: 0;
    padding-bottom: 50px;
  }
  
  h2 {
    text-align: center;
    color: #343234;
    padding-top: 15px;
    font-weight: 500;
  }

  @media screen and (max-width: 839px) {
    .projects {
      flex-direction: column;
    }
    
    .projects {
      margin-bottom: 70px;
    }
    
    .projects > :last-child {
      margin-top: 25px;
    }
  }
`;
