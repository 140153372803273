import styled from "styled-components";

export const HomeStyles = styled.div`
    
    .page-content {
      position: absolute;
      width: 100vw;
      top: 82px;
    }
`
