import React from 'react';
import { HeroImageStyles } from "./styles";
import {CustomBtn} from "../button/styles";



function HeroImage() {
    return (
        <>
            <HeroImageStyles>
                <div className='hero-text'>
                    <h1>Technology Solutions for your Business.</h1>
                    <h3>We build reliable and scalable technology platforms for digital businesses.</h3>
                    <div style={{marginTop: '30px'}}>
                        <CustomBtn primary>Our Work</CustomBtn>
                        <CustomBtn>Contact Us</CustomBtn>
                    </div>
                </div>
            </HeroImageStyles>
        </>
    )
}

export default HeroImage
