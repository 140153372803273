import React from 'react';
import { ServicesCardStyles } from "./styles";

function ServicesCard(props) {
    const { img_url, heading, description, padding_top } = props;

    return (
        <>
            <ServicesCardStyles>
                <div style={{paddingTop: (padding_top) && padding_top}}>
                    <img src={img_url} alt={heading}/>
                    <div className='heading'>{heading}</div>
                    <div className='description'>{description}</div>
                </div>
            </ServicesCardStyles>
        </>
    )
}

export default ServicesCard
