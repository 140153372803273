import React from 'react';
import {ServicesStyles} from "./styles";
import ServicesCard from "./services-card";
import consulting_icon from '../../../../assets/images/consulting-icon.svg';
import team_building from '../../../../assets/images/team-building.svg';
import growth_chart from '../../../../assets/images/growth-chart.svg'

function Services() {


    const servicesArray = [
        {
            img_url: consulting_icon,
            heading: 'Technology Consulting',
            description: 'We offer technology consulting for individuals as well as companies.'
        },
        {
            img_url: team_building,
            heading: 'Team Building',
            description: 'We offer team building to ensure development in your company.'
        },
        {
            img_url: growth_chart,
            heading: 'Business Process Improvement',
            description: 'We help your business improve with in-depth analysis of company efficiency.'
        }
    ];

    return (
        <>
            <ServicesStyles id='services'>
                <div>
                    <h2>Services</h2>
                    <div className='cards-container'>
                        {
                            servicesArray
                                .map((value, index) =>
                                    <ServicesCard
                                        key={ index }
                                        img_url={value.img_url}
                                        heading={value.heading}
                                        description={value.description}
                                        padding_top={(index === 0) && '17px'}/>)
                        }
                    </div>
                </div>
            </ServicesStyles>
        </>
    )
}

export default Services
