export default  {
  INDEX:"INDEX",
  DISPATCH_USER:"DISPATCH_USER",
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",
  ADD_TO_SENDER_ID_LIST: "ADD_TO_SENDER_ID_LIST",
  ADD_ALL_SENDER_ID_TEMPLATES: "ADD_ALL_SENDER_ID_TEMPLATES",
  SET_MSISDN: "SET_MSISDN",
  SET_ID_DOC_PATH: "SET_ID_DOC_PATH",
  SET_SENDER_ID_PROPERTIES: "SET_SENDER_ID_PROPERTIES",
  REMOVE_FROM_SENDER_ID_PROPERTIES: "REMOVE_FROM_SENDER_ID_PROPERTIES",
  ADD_INDEX: "ADD_INDEX",
  ADD_TO_SAVED_CARDS: "ADD_TO_SAVED_CARDS",
  SET_SAVED_CARDS: "SET_SAVED_CARDS",
  ADD_TO_SAVED_MOMO_NUMBERS: "ADD_TO_SAVED_MOMO_NUMBERS",
  SET_SAVED_MOMO_NUMBERS: "SET_SAVED_MOMO_NUMBERS",
  SET_WALLET_BALANCE: "SET_WALLET_BALANCE",
  SET_SMS_COUNT: "SET_SMS_COUNT",
  SET_BUNDLE_DESCRIPTION: "SET_BUNDLE_DESCRIPTION",
  SET_BUNDLE_TITLE: "SET_BUNDLE_TITLE",
  SET_INSUFFICIENT_BALANCE_TEXT: "SET_INSUFFICIENT_BALANCE_TEXT",
  SET_BUNDLE_PACKAGES: "SET_BUNDLE_PACKAGES"
}