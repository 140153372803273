import styled from 'styled-components';
import hero_image from "../../../../assets/images/hero-image.svg";

export const HeroImageStyles = styled.div`
  //background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${hero_image});
  background: url(${hero_image});
  background-color: rgba(34, 34, 34, 0.78);
  /* Set a specific height */
  //height: 500px;
  min-height: calc(100vh - 7rem);
  width: 100%;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  opacity: 1 !important;
  //width: 97vw;
  //height: 30rem;

  .hero-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: snow;
  }
  
  .hero-text > h1 {
    font-size: 3.9rem;
  }

  @media screen and (max-width: 768px) {
    .hero-text > h1 {
      font-size: 2.6rem;
    }
    
    .hero-text > h3 {
      font-size: 1rem;
    }
  }
`
