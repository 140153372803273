import styled from 'styled-components';

export const Textbox = styled.input`
  background: #FFFFFF;
  border: 2px solid #BDBDBD;
  box-sizing: border-box;
  border-radius: 5px;
  height: 35px;
  width: 370px;
  display: block;
  margin-top: 5px;
  padding-left: 10px;
  
  ::placeholder {
    padding-left: 3px;
  }

  @media screen and (max-width: 839px) {
    width: 100%;
  }
`
