import styled from 'styled-components';

export const ServicesCardStyles = styled.div`
  //position: absolute;
  width: 305px;
  height: 266px;
  left: 116px;
  top: 903px;

  background: #FFFFFF;
  border: 1px solid #ECECEC;
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  
  text-align: center;
  
  .heading {
    color: #505050;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 30px;
  }
  
  .description {
    color: #505050;
    font-size: .94rem;
  }

  @media screen and (max-width: 839px) {
    margin-bottom: 80px;
  }
`
