import styled from 'styled-components';
import sponsors from '../../../../assets/images/sponsors.svg'

export const ClientsStyles = styled.div`
  //height: 50vh;
  background: #fff;
  margin-top: 120px;

  h2 {
    text-align: center;
    color: #343234;
    padding-top: 35px;
    font-weight: 500;
  }
  
  .sponsors {
    text-align: center;
  }
  
  .sponsors > img {
    max-width: 100%;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    margin-top: 40px;
    
    .sponsors > img {
      width: 95% !important;
      height: 95% !important;
    }
  }

  // .sponsors {
  //   width: 90%;
  //   height: 50rem;
  //   margin-left: auto;
  //   margin-right: auto;
  //   background: url(${sponsors}) no-repeat 20% 0;
  //   background-size: 70%;
  // }
`
