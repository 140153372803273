import styled from 'styled-components';

export const ServicesStyles = styled.div`
  h2 {
    text-align: center;
    color: #343234;
    font-weight: 500;
    padding-top: 30px;
    padding-bottom: 20px;
  }
  
  .cards-container {
    display: flex;
    justify-content: space-evenly;
  }

  @media screen and (max-width: 839px) {
    .cards-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;
