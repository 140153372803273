import React, { useState, useEffect } from "react";
import ReCaptcha from 'react-google-recaptcha'
import { ContactStyles } from "./styles";
import {Textbox} from "../textbox/styles";
import {PrimaryBtn} from "../button/styles";

function Contact() {
    const [isVerfied, setIsVerified] = useState(false);

    const handleToken = (token) => {
        // setForm((currentForm) => {
        //     return {...currentForm, token }
        // })

        console.log(token);
    }

    const handleExpire = () => {
        // setForm((currentForm) => {
        //     return {...currentForm, token: null }
        // })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        console.log(process.env.REACT_APP_SITE_KEY);
    });

    return (
        <>
            <ContactStyles id='contact'>
                <div>
                    <h2>Contact</h2>
                    <div className='contact-details'>
                        <div>Lets Get in Touch.</div>
                        <div>
                            <form onSubmit={handleSubmit}>
                                <label htmlFor="name">Name</label>
                                <Textbox id='name' type="text" autoComplete='off' placeholder='Enter name'/>

                                <p /><p />

                                <label htmlFor="email">Email</label>
                                <Textbox id='email' type="email" autoComplete='off' placeholder='Enter email'/>

                                <p /> <p />

                                <label htmlFor="message">Message</label>
                                <textarea name="message" id="message" cols="30" rows="8" placeholder='Type message here'/>
                                <div className='recaptcha-holder'>
                                    <ReCaptcha sitekey={process.env.REACT_APP_SITE_KEY}
                                               onChange={handleToken}
                                               onExpire={handleExpire}
                                    />
                                </div>
                                <div>
                                    <PrimaryBtn type='submit' width='164px' height='40px'>Send</PrimaryBtn>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ContactStyles>
        </>
    )
}

export default Contact
