import styled from 'styled-components';

export const ContactStyles = styled.div`
  background: #fff;
  padding-bottom: 50px;
  
  h2 {
    text-align: center;
    padding-top: 30px;
    font-weight: 500;
    color: #343234;
  }
  
  .contact-details {
    display: flex;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .contact-details > div:first-child {
    color: #343234;
    font-size: 1.579rem;
    font-weight: 500;
    margin-top: 7%;
  }
  
  .contact-details > div:last-child {
    margin-left: 28%;
  }
  
  .contact-details label {
    color: #313232;
    font-size: .87rem;
  }
  
  .recaptcha-holder,
  .recaptcha-holder + div {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  
  .recaptcha-holder + div {
    margin-top: 30px;
  }
  
  textarea {
    display: block;
    background: #FFFFFF;
    border: 2px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 20px;
    width: 370px;
    padding-left: 10px;
    resize: none;

    ::placeholder {
      padding-left: 2px;
    }
  }

  @media screen and (max-width: 768px) {
    .contact-details {
      flex-direction: column;
      align-items: center;
    }
    
    .contact-details > :first-child {
      margin-bottom: 60px;
      margin-left: 0 !important;
    }

    .contact-details > div:last-child {
      margin-left: 0 !important;
    }
  }
  
  @media screen and (max-width: 839px) {
    .contact-details {
      width: 90%;
    }
    
    .contact-details > div:last-child {
      width: 90%;
    }
    
    .contact-details form {
      width: 100%;
    }
    
    .contact-details form > textarea {
      width: 100%
    }
  }
`
