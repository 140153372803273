import React from "react";
import {ClientsStyles} from "./styles";
import sponsors from '../../../../assets/images/sponsors.svg'

function Clients() {

    return (
        <>
            <ClientsStyles id='clients'>
                <div>
                    <h2>Clients</h2>
                    <div className='sponsors'>
                        <img src={sponsors} alt="Sponsors" style={{width: '60%', height: '60%'}}/>
                    </div>
                </div>
            </ClientsStyles>
        </>
    )
}

export default Clients
