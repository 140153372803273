const basePaths = {
    home: '/',
    services: '/services',
    clients: '/clients',
    projects: '/projects',
    contact: '/contact'
}

export const CONSTANTS = {
    basePaths
}
