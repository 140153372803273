import styled from 'styled-components';
// import { NavLink as Link } from 'react-router-dom';
import { Link } from 'react-scroll';
import { FaBars, FaTimes } from 'react-icons/fa';

export const NavbarStyles = styled.nav`
  //height: 80px;
  display: flex;
  //justify-content: space-between;
  //padding: 0.5rem calc((100vw - 1000px) / 5);
  //position: relative;
  z-index: 10;
  background: #fff;
  
  & {
    position: fixed;
    width: 100vw;
    border-bottom: 1px solid #C0C0C0;
  }

  #nav-menu-holder > div > a::after {
    content: '';
    display: block;
    position: absolute;
    height: 3px;
    width: 0;
    top: 55px;
    background: transparent;
    transition: width .7s ease, background-color .5s ease;
  }

  #nav-menu-holder > div > a:hover::after {
    width: 70%;
    background: black;
  }
`

export const NavLink = styled(Link)`
  color: #000;
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  
  h2 {
    font-size: 1.8rem;
    color: #505050;
  }
  
  img {
    margin-top: 12px;
  }
  
  &.active {
    color: #505050;
  }
  
  nav > &:first-child {
    margin-left: 40px;
  }
  
  @media screen and (max-width: 768px) {
    #nav-menu-holder & {
      color: white;
      border-bottom: 1px solid white;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 30px;
    }

    #nav-menu-holder &:hover {
      color: #1275D0;;
    }

    nav > &:first-child {
      margin-left: 5px;
    }

    h2 {
      font-size: 1.6rem;
    }
  }

  //width: 100%;
  //justify-content: center;
`

export const Bars = styled(FaBars)`
  display: none;
  color: #000;
  
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`

export const Times = styled(FaTimes)`
  display: none;
  color: #000;
  
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  right: 3%;
  position: absolute;
  height: 100%;
  font-size: .95rem;
  
  
  
  @media screen and (max-width: 768px) {
    display: none;
    //display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    border-top: 1px solid #fff;
    position: absolute;
    
    color: white;
    top: 80px;
    opacity: 1;

    &.menu-active {
      display: flex;
      background-color: rgba(0, 0, 0, .97);
      left: 0;
      opacity: 1;
      transition: all .5s ease-in;
      transform-origin: top;
      z-index: 1;
    }
  }
`
