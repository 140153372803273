import styled from 'styled-components';

export const Button = styled.button`
  width: 200px;
  height: 40px;
  border-radius: 4px;
  background: transparent;
  color: #343234;
  border: 3px solid #000;
  font-size: 16px;
  font-weight: 500;
  
  :hover {
    cursor: pointer;
  }
`

export const PrimaryBtn = styled(Button)`
  background: #136BB6;
  color: white;
  border: none;
  width: ${props => props.width};
  height: ${props => props.height};
  font-size: .89rem;
`

export const CustomBtn = styled(Button)`
  background: ${props => props.primary ? 'white' : 'transparent'};
  color: ${props => props.primary ? '#1275D0': 'white'};
  border: 1px solid white;
  
  &:first-child {
    margin-right: 20px;
  }

  @media screen and (max-width: 839px) {
    &:first-child {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
`
