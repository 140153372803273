import React from "react";
import Navbar from "../../@shared/navbar";
import HeroImage from "../../@shared/about-company";
import Services from "../../@shared/services";
import Clients from "../../@shared/clients";
import Projects from "../../@shared/projects";
import Contact from "../../@shared/contact";
import {HomeStyles} from "./styles";

const Home = () => {

    return (
        <>
            <HomeStyles>
                <Navbar />
                <div className='page-content'>
                    <HeroImage />
                    <Services />
                    <Clients />
                    <Projects/>
                    <Contact />
                </div>
            </HomeStyles>
        </>
    )
}

export default Home;
