import React from "react";
// React Router DOM
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// React Redux
import { Provider } from "react-redux";
import configureStore from './app/data/redux/configureStore';
import { PersistGate } from 'redux-persist/lib/integration/react';
// Application properties
import { CONSTANTS } from "./app/utils/constants";
import Error404 from "./app/presentation/main/error404";
import Home from "./app/presentation/main/home";
import Projects from "./app/presentation/@shared/projects";
// Font styles
// import './assets/fonts/font.css';


const {store, persistor} = configureStore();


const App = () => (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router>
          <Switch>
            <Route path={CONSTANTS.basePaths.home} exact component={Home} />
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
);


export default App;
