import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-scroll';
import { NavbarStyles, Bars, Times, NavLink, NavMenu } from "./styles";
import { CONSTANTS } from "../../../utils/constants";
import logo from "../../../../assets/images/company_logo.svg";

function Navbar() {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);

    const closeMobileMenu = () => setClick(false);

    // const a = () => {
    //     if (click && window.innerWidth <= 768) {
    //         document.body.style.overflow = 'hidden';
    //     } else document.body.style.overflow = 'auto';
    // }
    //
    // useEffect(() => {
    //     window.addEventListener('resize', a);
    //
    //     return () => {
    //         window.removeEventListener('resize', a);
    //     }
    // });

    return (
        <>
            <NavbarStyles>
                <NavLink to={ CONSTANTS.basePaths.home }>
                    <img src={logo} alt="Company Logo" style={{width: '70px', height: '70px'}}/>
                    <h2>Lucid Array</h2>
                </NavLink>
                <div onClick={handleClick}>
                    {
                        click ? <Times /> : <Bars />
                    }
                </div>
                <div id='nav-menu-holder'>
                    <NavMenu className={click ? 'menu-active' : ''}>
                        {/*<NavLink activeClass="projects"*/}
                        {/*      spy={true}*/}
                        {/*      smooth={true}*/}
                        {/*      offset={-70}*/}
                        {/*      duration={1000}*/}
                        {/*    to="services"*/}
                        {/*activeClassName={'active'}>LINK TO HASH FRAGMENT</NavLink>*/}
                        <NavLink activeClass="services" onClick={closeMobileMenu}
                                 spy={true}
                                 smooth={true}
                                 offset={-100}
                                 duration={1000}
                                 to="services"
                                 activeClassName={'active'}>
                            SERVICES
                        </NavLink>
                        {/*<NavLink to={ CONSTANTS.basePaths.clients } activeStyle>*/}
                        {/*    CLIENTS*/}
                        {/*</NavLink>*/}
                        <NavLink activeClass="clients" onClick={closeMobileMenu}
                                 spy={true}
                                 smooth={true}
                                 offset={-100}
                                 duration={1000}
                                 to="clients"
                                 activeClassName={'active'}>
                            CLIENTS
                        </NavLink>
                        {/*<NavLink to={ CONSTANTS.basePaths.projects } activeStyle>*/}
                        {/*    PROJECTS*/}
                        {/*</NavLink>*/}
                        <NavLink activeClass="projects" onClick={closeMobileMenu}
                                 spy={true}
                                 smooth={true}
                                 offset={-100}
                                 duration={1000}
                                 to="projects"
                                 activeClassName={'active'}>
                            PROJECTS
                        </NavLink>
                        {/*<NavLink to={ CONSTANTS.basePaths.contact } activeStyle>*/}
                        {/*    CONTACT*/}
                        {/*</NavLink>*/}
                        <NavLink activeClass="contact" onClick={closeMobileMenu}
                                 spy={true}
                                 smooth={true}
                                 offset={-70}
                                 duration={1000}
                                 to="contact"
                                 activeClassName={'active'}>
                            CONTACT
                        </NavLink>
                    </NavMenu>
                </div>
            </NavbarStyles>
        </>
    )
}

export default Navbar
